/* ************ Reset Css  ************** */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  // scroll-behavior: auto;

}

/* ************ Custom Fonts ************** */

@font-face {
  font-family: darkFont;
  src: url('Assets/Fonts/avantgarde-bk-bt/AVGARDD_2.TTF');
}

@font-face {
  font-family: cursiveDark;
  src: url('Assets/Fonts/avantgarde-bk-bt/AVGARDDO_2.TTF');
}

@font-face {
  font-family: lightFont;
  src: url('Assets/Fonts/avantgarde-bk-bt/AVGARDN_2.TTF');
}
body {
  height: 100vh;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}


/* ************ Tailwind INIT ************** */
@tailwind base;
@tailwind components;
@tailwind utilities;


/* ************ Css Variables ************** */

:root {
  --light: #fff;
  --dark: #000000;
  --themeColor: #FCD117;
  --lightYellow: #FFF5C9;
}

/* ************ Custom Classes ************** */

.container {
  max-width: 1400px;
  margin: 0 auto;

  @media screen and (max-width:"1450px") {
    max-width: 1270px;
    padding: 0 0px;
  }
}




.cursor {
  cursor: pointer;
}

input {
  accent-color: #FCD117;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-border-color: transparent !important;
  -webkit-outline: 0 !important;
  outline-offset: 0 !important;
}


// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//    -webkit-transition-delay: 9999s;
//    transition-delay: 9999s;
// }



/* ************ Custom  ScrollBar ************** */

::-webkit-scrollbar {
  // display: none;
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: transparent;
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, .3) !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: #5c5b5b;
}

.flexLine {
  width: -webkit-fill-available;
  display: flex;
  gap: 1rem;
}



.css-doq0dk {
  height: 400px !important;
}

.css-doq0dk .css-1fzpoyk {
  display: none;
  opacity: 0.7 !important;
  z-index: 1 !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(1),
.css-doq0dk .css-1fzpoyk:nth-child(5) {
  opacity: 0.25 !important;
  z-index: 0 !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(1),
.css-doq0dk .css-1fzpoyk:nth-child(2),
.css-doq0dk .css-1fzpoyk:nth-child(3),
.css-doq0dk .css-1fzpoyk:nth-child(4),
.css-doq0dk .css-1fzpoyk:nth-child(5) {
  display: flex;
}

.css-doq0dk .css-1fzpoyk img {
  width: 100%;
  object-fit: cover;

}

.css-doq0dk .css-1fzpoyk:nth-child(1) {
  left: 7% !important;
  width: 53%;
}

.css-doq0dk .css-1fzpoyk:nth-child(2) {
  left: 28% !important;
  width: 30%;
}

.css-doq0dk .css-1fzpoyk:nth-child(4) {
  width: 30%;
  left: 70% !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(3) {
  width: 36%;
  opacity: 1 !important;
  z-index: 2 !important;
}

.css-doq0dk .css-1fzpoyk:nth-child(5) {
  left: 81% !important;
  width: 50%;
  transform: translateY(-50%) translateX(-33.6667%) scale(0.333333) !important;
  -webkit-transform: translateY(-50%) translateX(-33.6667%) scale(0.333333) !important;
  -moz-transform: translateY(-50%) translateX(-33.6667%) scale(0.333333) !important;
  -ms-transform: translateY(-50%) translateX(-33.6667%) scale(0.333333) !important;
  -o-transform: translateY(-50%) translateX(-33.6667%) scale(0.333333) !important;
}

.css-1qzevvg {
  display: none !important;
}